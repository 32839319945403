import { Seo } from "~/components";
import { IndexPage } from "~/page-components";
import React from "react";

export default function Index() {
  return (
    <>
      <Seo />
      <IndexPage />
    </>
  );
}
